@import '/src/styles/colours';

.start_pib_form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  :global(.ant-row.ant-form-item-row) {
    width: 100% !important;
  }

  :global(.ant-btn) {
    width: 100% !important;
  }
}
.pibFormDiv {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  & > div {
    button {
      color: $brand-dark !important;
      border-color: $brand-dark !important;
      justify-self: flex-end;
    }
  }
}
