@import '/src/styles/colours';
@import '/src/styles/mixins';

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ul {
    @include dashed-ul;
    width: 100%;
    text-align: start;
  }
}

.postrojenjeNaziv {
  color: $form-orange;
}
