.printButtonContainer {
  display: flex;
  align-content: flex-end;
  flex-wrap: wrap;
}

.second-table {
  margin-top: 2rem;
}

:global(.vertical-align-top) {
  vertical-align: top !important;
}
