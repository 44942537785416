.ukupnaKolicinaPoAktivnostimaWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
}

.aktivnostRow {
  text-align: center;
  height: 80px;
  border-bottom: 1px solid #f0f0f0;
  padding-inline: 10px;
  align-content: center;
  background-color: #fafafa;
  font-weight: bold;
}
