@import '/src/styles/variables';
@import '/src/styles/colours';

.obrazacWizardPage {
  width: 100%;
}

// HEADER
.wizardHeader {
  background: $brand-dark;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0px 20px;
  height: 64px;
  width: 100%;
  :global(.ant-form-item) {
    margin-bottom: 0px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.copyPrint {
  margin-right: 10px;
  display: flex;
  gap: 10px;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.wizardTabWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  :global(.ant-tabs-nav-list) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: $brand-dark;
  }

  :global(.ant-tabs-tabpane-active) {
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
  }
}

.scrollable {
  overflow-y: overlay;
  overflow-x: hidden;
  height: calc(100vh - 128px);
}

.wizardContent {
  display: flex;
  flex-direction: column;
  width: calc(100vw - $sidebar-width);
  overflow-y: scroll;
  background-color: $neutral;

  .content {
    margin: 20px;
    // flex: 1 0 auto;
    // display: flex;
    // flex-direction: coslumn;
    min-height: calc(100vh - 104px);
  }
}

.navTab {
  padding: 0px 24px;
  color: white;
  width: $sidebar-width;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  cursor: pointer;
  &:not(:last-of-type) {
    border-bottom: 1px solid ghostwhite;
  }

  &:hover {
    background-color: #335867;
  }

  &:global(.active) {
    background-color: #ffffff;
    color: $brand-dark;
    border-right: none;
  }
}

.navSubTab {
  background-color: $brand-dark;
}

.navTabCollapse {
  background: inherit;
  width: 100%;

  &:global(.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
    padding: 0px;
  }

  :global(.ant-collapse-header) {
    padding: 0px 24px !important;

    width: 234px;
    height: 60px;
    color: white !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center !important;
  }
}

:global(.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow) {
  margin: 0px 0px 0px 12px;
}
