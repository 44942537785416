.checkbox :global(.ant-form-item-control-input-content) {
  display: flex;
  justify-content: flex-end;
}
.wrapper {
  :global(.ant-col-7) {
    max-width: 28.7% !important;
  }
  :global(.ant-col-17) {
    max-width: 71.3% !important;
  }
}
