.formListWrapper {
  margin-top: 50px;
  height: 100%;
}

@keyframes easeIn {
  0% {
    scale: 0;
  }
  100% {
    scale: 1;
  }
}

.formListError {
  color: red;
  padding-left: 8px;
  animation-name: easeIn;
  animation-duration: 0.2s;
}

.tableMarginBottom {
  margin-bottom: 20px;
}
