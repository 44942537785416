@import '/src/styles/variables';

.logout_div {
  height: 64px;
  display: flex;
  justify-content: center;
  :global(.ant-btn-default.ant-btn-background-ghost) {
    border: 0px !important;
    border-color: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: red !important;
    }
  }
}

.logout_btn {
  width: 100%;
  margin: 15px;
  & img {
    width: 20px;
    height: 20px;
  }
  :global(.anticon-logout) {
    rotate: 180deg;
  }
}
