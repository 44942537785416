@import '../../../../../styles/colours.scss';

.zakljucavanjePoTipuHeader {
  display: flex;
  justify-content: space-between;
}
.zakljucavanjeSegment {
  color: $brand-dark;
  background-color: $lightBlue;
  & :global(.ant-segmented-item) {
    &:hover {
      color: $primary-medium !important;
    }
  }
  & :global(.ant-segmented-item-selected) {
    &:hover {
      color: $brand-dark !important;
    }
    color: $brand-dark;
  }
}
