.water_pollution_modal {
  top: 10px !important;
  font-weight: bold;

  :global(.ant-row.ant-row-end) {
    height: 32px;
  }

  :global(.ant-modal.ant-modal-content) {
    padding: 0px 0px;
  }
}
