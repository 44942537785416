@import '/src/styles/colours';

.form {
  min-height: 190px;
}

.checkboxGroup {
  width: 100%;
}

.checkboxCol {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
}

.warningMessage {
  color: $form-orange;
  font-weight: normal;
}

.tipoviObrazacaWrapper {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 10px;
}
