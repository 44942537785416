.lcpHeader {
  display: flex;
  justify-content: space-between;
}

.lcpSubmitButtons {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
}
