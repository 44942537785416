@import '/src/styles/colours';

.permitDiv {
  background-color: $white;
  padding: 20px;
  margin: 20px 0 0 20px;

  :global(.ant-row) {
    justify-content: space-between;
  }
}
