@import '/src/styles/colours';

.wrapper {
  background-color: $white;
  padding: 20px;
}

.buttonDiv {
  display: flex;
  justify-content: space-between;
  button {
    cursor: pointer;
  }
}

.statusDiv {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  // margin-top: 20px;
}
