@import '/src/styles/colours';
.plus {
  :global(.ant-btn) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin-right: 5px;
    border-color: transparent !important;
    // background: #ffffff !important;
    // color: #26355a !important;
    border-radius: 5px;
    border: 0px !important;
    span {
      font-weight: 500;
    }
    svg {
      color: $white !important;
    }

    &:hover {
      background: transparent !important;
      z-index: 200;
      scale: 1.05;
      color: $primary-light !important;
      border: 1px solid $primary-light !important;
      // border-color: white !important ;
      svg {
        color: $primary-light !important;
      }
    }
  }
  svg {
    font-weight: bold;
  }
}
