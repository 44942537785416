@import '/src/styles/colours';

.categories {
  margin-left: 10px;
  font-weight: bold;
  display: flex;
}

.select {
  width: 80px;
}

.form_item {
  width: 150px;
}

.border_wrapper {
  margin-bottom: 10px;
  padding: 20px;
}

.error_border {
  border: 1px solid $warningRed;
}

.neutral_border {
  border: 1px solid $neutral;
}

.errorMsg {
  color: $warningRed;
  margin-bottom: 10px;
  height: 20px;
}
