.wrapper {
  position: relative;
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  max-width: 100%;
  padding: 4px 11px;
  margin: 22px 6px 0 6px;
}

.title {
  position: absolute;
  background-color: white;
  color: rgba(7, 41, 92, 0.96);
  border-radius: 6px;
  top: -14px;
  left: 5px;
  padding: 2px;
}
.checkboxWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  :global(.ant-form-item-control) {
    width: auto !important;
    margin-right: 10px;
  }

  :global(.ant-form-item):last-of-type {
    & :global(.ant-form-item-control) {
      margin-right: 0;
    }
  }

  // this is set because of the vertical layout property in the FilterTableForm component. It hides the empty space where the colon's supposed to be.
  :global(.ant-form-item-label) {
    & label::after {
      content: '';
    }
  }

  :global(.ant-form-item-row) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
