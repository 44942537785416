.aktivnostRow {
  min-height: 60px;
  //   border: 1px solid black;
  border-top: none;
  background-color: white;
  padding: 5px;
  border-bottom: 1px solid #f0f0f0;

  &.activeRow {
    background-color: #dfdfdf;
  }

  & :global(.ant-col) {
    text-align: center;
    // padding: 10px;
    // border-right: 1px solid black;
    // height: 100%;
    align-content: center;
    flex-wrap: wrap;
    &:last-of-type {
      border-right: none;
    }
  }

  & :global(.ant-input-number) {
    width: 40%;
    & input {
      text-align: center;
    }
  }
  & :global(.ant-input-number-disabled) {
    color: inherit !important;
    background-color: inherit !important;
    border: none !important;
  }
}
