@import '/src/styles/colours';
.filter {
  display: flex;
  color: $brand-dark !important;
  :global(.ant-form-item) {
    margin-bottom: 6px !important;
  }
}

.visibility {
  margin-bottom: 10px !important;
  font-size: 12px !important;
  :global(.ant-tabs-nav-operations) {
    display: none !important;
  }
  :global(.ant-tabs-tab-active) {
    background: transparent !important;
    border-bottom: 2px solid #1677ff !important;
  }
}

.filterInput {
  width: 200px;
  :global(.ant-form-item) {
    margin-bottom: 0px !important;
  }
}

.tipovi_obrazaca_filter {
  display: flex;
  background: white;
  padding-left: 5px;
  border-radius: 5px;
  align-items: center;
  width: 500px;
}
