@import '/src/styles/colours';

.survey_form {
  padding: 0 20px 20px 20px;
  width: 100%;
}

.surveyHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  & button {
    color: $brand-dark !important;
    border-color: $brand-dark !important;
  }
  & h1 {
    margin: 15px;
  }
}

.surveyWrapper {
  max-height: 100vh;
}

.btn {
  display: flex;
  justify-content: flex-end;
}
