@import '../../styles/colours.scss';
@import '../../styles/typography.scss';

.ant-btn {
  border-color: $primary-light;
  background: $primary-light;
  color: white;
  border-radius: 4px;

  span {
    @include text-strong();
  }

  &:hover {
    border-color: $primary-medium;
    background: $primary-medium;
    color: white;
  }

  &:active {
    border-color: $primary-dark;
    background: $primary-dark;
    color: white;
  }

  &:disabled {
    background-color: $neutral-light;
    color: white;

    &:hover {
      background-color: $neutral-light;
    }
  }
}

.ant-btn-dangerous {
  border-color: $alert-light2;
  background: $alert-light2;
  color: $white !important;

  &:hover {
    border-color: $alert-medium;
    background: $alert-medium;
  }

  &:active,
  &:focus {
    border-color: $alert-dark;
    background: $alert-dark;
  }
}

.ant-btn-primary {
  border-color: $brand-dark;
  background: $brand-dark;
  color: whitesmoke;

  &:hover {
    color: $brand-dark !important;
    border-color: $brand-dark !important;
    background-color: $brand-light !important;

    svg {
      color: $brand-dark;
    }
  }

  &:active,
  &:focus {
    color: $brand-light;
    border-color: $brand-light;
    background: $brand-dark;
  }
}
.ant-btn-secondary {
  border-color: $primary-light;
  background: transparent;
  color: $primary-light;

  svg {
    color: $primary-light;
  }

  &:hover {
    color: white !important;
    border-color: white !important;
    background: $primary-light !important;

    svg {
      color: white;
    }
  }

  &:active {
    border-color: $primary-dark;
    background: transparent;
    color: $primary-dark;
  }

  &:focus {
    color: white !important;
    border-color: white !important;
    background: $primary-dark;

    svg {
      color: white;
    }
  }
}

.ant-btn-ghost {
  border-color: red;
  background: red !important;

  &:hover {
    border-color: white;
    background: white;
    color: $brand-dark;
  }

  &:active {
    border-color: white;
    background: white;
    color: $brand-dark;
  }
}

.ant-btn-default {
  &:hover {
    background: transparent !important;
    z-index: 200;
    scale: 1.05;
    color: $primary-light !important;
    border: 1px solid $primary-light !important;
    // border-color: white !important ;
    svg {
      color: $primary-light !important;
    }
  }
}
