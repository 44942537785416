.wrapper {
  padding-left: 10px;

  :global(.ant-btn) {
    box-shadow: 1px 5px 0px rgba(38, 53, 90, 0.7882352941);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin-right: 5px;
    border-color: transparent !important;
    background: #ffffff !important;
    color: #26355a !important;
    border-radius: 5px;
    border: 0px !important;
    span {
      font-weight: 500;
    }
    svg {
      color: #26355a !important;
    }

    &:hover {
      background: #659ad1 !important;
      color: #26355a !important;
      z-index: 200;

      scale: 1.05;
      border-color: white !important;
      svg {
        color: #26355a !important;
      }
    }
  }

  :global(.ant-col-19) {
    padding: 0px !important;
  }
}

.filterTitle {
  position: absolute;
  top: -8px;
  left: 10px;
  background: #ebeaea;
  border-radius: 5px;
  padding: 0px 10px;
  color: #26355a;
  &:hover {
    cursor: pointer;
  }
}

.collapsible {
  overflow: hidden;

  transition: max-height 0.3s ease, padding 0.3s ease;
  padding-bottom: 5px;
}

.input_wrapper {
  display: flex;
  align-items: flex-end;
}

.form {
  position: relative;
  padding: 16px !important;
  background: rgb(255, 255, 255);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(2px);

  :global(.ant-row-space-between) {
    justify-content: flex-start !important;
  }

  :global(.ant-col-22) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  :global(.ant-col-5) {
    padding-left: 0px !important;
    padding-right: 4px !important;
  }
  :global(.ant-col-2) {
    padding-left: 2px !important;
    padding-right: 0px !important;
  }

  :global(.ant-form-item) {
    margin-bottom: 0px !important;
  }

  :global(.ant-btn) {
    align-items: center;
    justify-content: center;
    padding: 10px;
    display: flex;
    margin-left: 10px;

    svg {
      color: #ffffff !important;
    }
    &:hover {
      background: white !important;
      color: #659ad1 !important;
      z-index: 200;

      scale: 1.05;

      svg {
        color: #659ad1 !important;
      }
    }
  }

  :global(.ant-form-item-label) {
    padding: 0px !important;
  }

  label {
    font-size: 12px !important;
    color: rgba(7, 41, 92, 0.96) !important;
  }
}

.switch {
  display: flex;
  justify-content: center;
  :global(.ant-form-item-control-input) {
    display: flex;
    align-self: center;
  }
  :global(.ant-form-item-label) {
    text-align: center;
  }
}

.search {
  flex-direction: column;
  align-items: flex-end;
  align-content: flex-end;
  padding: 10px 0px 0px 0px;
}

.filterButtons {
  display: flex;
}
