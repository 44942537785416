.user_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  label {
    font-weight: 600;
  }
}

.disabledDropdown {
  :global(.ant-select-arrow) {
    display: none !important;
  }

  :global(.ant-select-selector) {
    border: 0px !important;
    box-shadow: none !important;
  }

  :global(.ant-select) {
    cursor: default;
  }

  :global(.ant-select-selector) {
    background-color: white !important;
    color: black !important;
    pointer-events: none;
  }

  :global(.ant-select-selection-item) {
    padding: 0 !important;
  }
}
