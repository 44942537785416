@import '/src/styles/colours';
@import '/src/styles/variables';
@import '/src/styles/mixins';

.nriz_logo {
  width: $sidebar-width;
  height: $logo-height;
  overflow: hidden;
}

.logout_div {
  display: flex;
  width: 100%;
  justify-content: center;
  bottom: 0px;
  position: absolute;
}

.logout_btn {
  width: 100%;
  margin: 20px;
}

.items {
  overflow-y: auto;
  overflow-x: hidden;

  @include scrollbar-color($primary-dark, $primary-medium);

  height: calc(100vh - $logo-height - $logout-btn-div) !important;
}

:global(.ant-layout.ant-layout-has-sider) {
  display: flex;
  width: $sidebar-width;
  flex-grow: 0;
  flex-shrink: 0;
}

:global(.ant-menu-item-selected) {
  background-color: $brand-light !important;
  border-radius: 0px !important;
  &:active {
    border-radius: 0px !important;
  }
}

:global(.ant-menu-item:not(.ant-menu-item-selected):hover) {
  background-color: rgba($color: $brand-light, $alpha: 0.2) !important;
  border-radius: 0px !important;
}

:global(.ant-layout .ant-layout-sider) {
  min-width: 0;
  border: 20px 0 0 20px !important;
  background: $brand-dark !important;
  height: 100%;
  position: fixed;
}

:global(.ant-layout-sider-children) {
  min-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

:global(.ant-menu-dark) {
  background-color: $brand-dark;
}

:global(.ant-menu-item) {
  width: 100% !important;
  margin-left: 0px !important;
  margin-block: 0px !important;
}

:global(.ant-menu-submenu-title) {
  margin: 0 !important;
  width: 100% !important;
  border-radius: 0 !important;
  &:hover {
    background-color: $brand-dark !important;
    color: #fff !important;
  }
}

:global(.ant-menu-item),
:global(.ant-menu-submenu-title) {
  line-height: 1.5 !important;
  text-wrap: wrap !important;
}

:global(.ant-menu-submenu-title:active) {
  background-color: $brand-dark !important;
  color: #fff;
}

:global(.ant-menu-sub.ant-menu-inline) {
  margin-left: 45px;
  background: rgba($color: $brand-light, $alpha: 0.2) !important;
  border: 0px !important;
  & > li {
    &:first-child {
      margin-top: 0px !important;
    }
    border-radius: 0px !important;
    margin-top: 5px !important;
    padding-left: 10px !important;
  }
}

:global(.ant-menu-item-icon) {
  font-size: 20px !important;
}
