.excel {
  :global(.ant-btn) {
    background: green !important;
    color: white !important;
    svg {
      color: white !important;
    }

    &:hover {
      background: rgb(151 216 54) !important;
      color: green !important;
      svg {
        color: green !important;
      }
    }
  }
}
