.izuzeciFormButtons {
  flex-direction: row-reverse;
  gap: 20px;
}

.izuzeciModal {
  & :global(.ant-modal-body) {
    padding-top: 30px;
  }
}
