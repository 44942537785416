@import '/src/styles/colours';

.wrapper {
  background-color: $white;
  padding: 20px;
}

.korekcijaCheckbox {
  width: 100% !important;
}

.korekcijaBtnCol {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  padding-bottom: 24px;
}
