@import '/src/styles/colours';

.companyInfoWrapper {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 10px;
}
.inputReadOnly {
  input {
    display: flex;
    text-align: left;
    border: 0px !important;

    :global(.ant-input-focus) {
      border: 0px !important;
      box-shadow: none !important;
    }
    :active {
      border: 0px !important;
      box-shadow: 0px 0px 0px 0px transparent !important;
    }
  }
}
