@import '/src/styles/variables';
@import '/src/styles/colours';

.logoWrapper {
  display: flex;
  font-size: 10px;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  color: white !important;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
}
.cover_image {
  margin-left: 30px;
  object-fit: contain;
  margin-bottom: 10px;
}
