.formTableButtons {
  flex-direction: row-reverse;
  gap: 20px;
}
.formModal {
  & :global(.ant-modal-body) {
    padding-top: 20px;
  }
}
.formModalTitle {
  margin-bottom: 40px;
}
