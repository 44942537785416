.wrapper_loader {
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(2, 0, 36, 0.5);
  position: fixed;
}

.loader {
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin-top: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
