@import '/src/styles/colours';

.type_of_discharged {
  :global(.ant-form-item) {
    margin-bottom: -8px;
  }
}

.type_options {
  padding: 10px;
  border: 1px solid rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
  :global(.ant-form-item:last-child) {
    padding-bottom: 8px;
  }
}

.text {
  font-weight: bold;
}

.warning {
  color: red;
}

.error {
  padding: 10px;
  border: 1px solid $warningRed;
  border-radius: 10px;
}
