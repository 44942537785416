.izuzeciHeader {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.izuzeciWrapper {
  width: 100%;
  margin-top: 50px;
}
