@import '/src/styles/colours';

.wrapper {
  width: 60%;
}

.header {
  font-weight: bold;
  margin-bottom: 20px;
}

.body {
  background-color: $white;
  padding: 20px;
}
