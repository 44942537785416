@import '/src/styles/colours';

.user_start_page {
  width: 100%;
  min-height: 100vh;
  overflow: scroll;
}

:global(.ant-alert) {
  text-align: center;
  width: 90%;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
}

.alertWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  & button {
    color: $brand-dark !important;
    border-color: $brand-dark !important;
  }
}
