.wrapper {
  margin-block: 20px;
}

.itemWrapper {
  margin-left: 20px;
}

.kolicina {
  color: red;
}
