@import '/src/styles/colours';

.user_registration {
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logInRedirect {
  color: $primary-dark;
  font-size: small;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

:global(.ant-alert) {
  text-align: center;
  width: 90%;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
}

.alertWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  & button {
    color: $brand-dark !important;
    border-color: $brand-dark !important;
  }
  p {
    font-size: medium;
  }
}
