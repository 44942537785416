@import '/src/styles/colours';

.activityModalInfo {
  color: $form-orange;
  font-weight: normal;
  text-align: justify;
}
.activityForm {
  :global(.ant-form-item-control-input-content) {
    display: flex;
    flex-direction: row-reverse;
  }
}
