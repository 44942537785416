.rowIconWrapper {
  display: flex;
  justify-content: center;
}

.saveIcon {
  background-color: green;
}

.cancelIcon {
  background-color: red;
}
