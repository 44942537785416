.new_water_pollution_form {
  :global(.ant-form-item-control-input-content) {
    display: flex;
    justify-content: flex-end;
  }
}

.wastewaterTreatment {
  margin-top: 20px;
}

.text {
  font-weight: bold;
}
