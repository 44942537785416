@import '~antd/dist/reset.css';
@import 'styles/variables';
@import 'styles/colours';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $brand-dark;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  height: 100%;
}

.ant-btn {
  &:hover {
    scale: 1.05;
  }
}

input {
  /* Hide the up and down arrows for the number input */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Hide the number input's arrow for Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.ant-input-number.ant-input-number-in-form-item {
  width: 100%;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Hide the number input's arrow for Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.ant-picker {
  width: 100%;
}

.ant-select {
  width: 100% !important;
}

.ant-tabs-nav {
  margin: 0px !important;
}
.ant-tabs-tab {
  border: 0px solid white !important;
}

.ant-switch.ant-switch-checked {
  background: #659ad1 !important;
}
