// .tableDiv {
//   th {
//     font-size: 10px;
//   }
//   & tr:nth-of-type(2) {
//     th {
//       font-size: 8px;
//     }
//   }
// }

.tableDiv {
  overflow-x: scroll;
}
