@import '/src/styles/_typography.scss';

.facilityUsersWrapper {
  display: flex;
  flex-direction: column;
}

.facilityUsersSegmented {
  @include text-bold;
  margin-bottom: 10px;
  align-self: flex-end;
}
