@import '../../../../../styles/colours';

.zakljucavanjeGodinaWrapper {
  width: 100%;
}
.zakljucavanjeGodinaObrasciHeader {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & :global(.ant-col-6) {
    display: flex;
    justify-content: flex-end;
  }
}

.zakljucavanjeGodineTooltip {
  background-color: $white;
  color: $primary-dark;
  & > * {
    margin: 0;
  }
  ul {
    list-style-type: none;
    padding-inline-start: 20px;
    color: $form-orange;
  }
  ul > li:before {
    content: '-';
  }
}
