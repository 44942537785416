@import '/src/styles/colours';

.sectionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(90deg, $brand-dark 50%, $brand-light 50%);
  box-shadow: 0px 0px 20px 1px $brand-dark;
  -webkit-box-shadow: 0px 0px 20px 1px $brand-dark;
  -moz-box-shadow: 0px 0px 20px 1px $brand-dark;
  border-radius: 10px;
  height: 450px;
  h2 {
    font-size: 20px !important;
  }
}

.componentWrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  height: 100%;
}

.title {
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translateX(-50%);
  color: $brand-dark;
}

// .logoWrapper {
//   width: 50%;
//   height: 60%;
//   margin: 0 auto;
//   position: relative;
//   img {
//     height: 100%;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//   }
// }

.row {
  width: 100%;
}
