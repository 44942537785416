.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 10px 0;
}

.btnWrapper {
  :first-child {
    margin-right: 10px;
  }
}
