@import '/src/styles/colours';
@import 'styles/variables';

.insideContentWrapper {
  position: relative;
  padding: 10px;
  margin-bottom: 30px;
  .header {
    height: 40px;
  }
}
