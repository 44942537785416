@import '/src/styles/colours';

.type_of_company {
  :global(.ant-form-item) {
    margin-bottom: -8px;
  }
}

.type_options {
  padding: 10px;
}

.text {
  font-weight: bold;
}

.error {
  color: $warningRed;
  padding: 10px 0 0 28px;
}
