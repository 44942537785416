@import '/src/styles/colours';
@import 'styles/variables';

.contentWrapper {
  display: flex;
  flex-direction: column;
  width: calc(100vw - $sidebar-width);
  overflow-y: scroll;
  background-color: $neutral;
  height: 100%;
  h1 {
    margin-bottom: 0px !important;
    font-size: 28px;
    font-weight: 400;
  }

  .header {
    flex: 0 0 auto;
    height: 76px;
    width: 100%;
    padding: 20px;
    background-color: $white;
  }

  .content {
    margin: 10px;
    // flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .btn {
    margin: 10px 10px;
  }
}
