.wrapper {
  :global(.ant-col-9) {
    flex: 0 0 36.9% !important;
    max-width: 36.9% !important;
  }
  :global(.ant-col-15) {
    flex: 0 0 63.1% !important;
    max-width: 63.1% !important;
  }
}
