@import '../../../../../styles/colours.scss';

.zakljucavanjeGodinaRow {
  min-height: 30px;
  //   border: 1px solid black;
  border-top: none;
  background-color: $white;
  padding: 10px;
  border-bottom: 1px solid $lightBlue;
  &:hover {
    background-color: $hoverBlue;
  }
}
