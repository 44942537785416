.readOnly {
  pointer-events: none;

  :global(.ant-select-arrow) {
    display: none !important;
  }

  // :global(.ant-select-selector) {
  //   border: 0px !important;
  //   box-shadow: none !important;
  // }
}
