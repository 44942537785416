@import '../../../../../styles/colours';

.locked {
  & :global(.ant-switch-inner) {
    background-color: rgba($warningRed, 0.8) !important;
  }
}

.unlocked {
  background-color: rgba($confirm-dark, 0.8) !important;
}
