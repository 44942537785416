@import '/src/styles/colours';

.wrapper {
  width: 15px;
  height: 26px;
  line-height: 0px !important;
  align-self: center;
  border-radius: 5px;
  display: grid;

  margin-bottom: 3px;
  &:hover {
    scale: 1.2;
    // box-shadow: 0px 1px 7px 0px rgba(38, 53, 90, 0.7882352941);
    // background: rgba(255, 255, 255, 0.512);
  }

  :global(.anticon) {
    vertical-align: 0px !important;
    svg {
      width: 10px;
    }
  }
}

.active {
  color: $brand-dark;
  box-shadow: rgb(31 38 135 / 38%) 0px 1px 5px 0px;
  background: rgb(255, 255, 255);
}

.inactive {
  background: rgba(228, 230, 237, 0.22);
  box-shadow: rgb(31 38 135 / 38%) 0px 1px 5px 0px;
  backdrop-filter: blur(2.5px);
}
