@import '/src/styles/colours';

.user_info_wrapper {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 10px;
  margin-bottom: 40px;

  input {
    display: flex;
    text-align: right;
    border: 0px !important;

    :global(.ant-input-focus) {
      border: 0px !important;
      box-shadow: none !important;
    }
    :active {
      border: 0px !important;
      box-shadow: 0px 0px 0px 0px transparent !important;
    }
  }
  textarea {
    border: 0px;
    border-width: 0px;
  }
}

.btn {
  background: transparent !important;
  color: $primary-light;
  border: 1px solid $primary-light !important;
  padding: 4px !important;
  border-radius: 5px;

  &:hover {
    color: $brand-dark !important;
    border: 1px solid $brand-dark !important;

    svg {
      color: $brand-dark !important;
    }
  }
}
