.form_pib_wrapper {
  label {
    font-weight: 600;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}
:global(.ant-popconfirm-title) {
  & p {
    margin: 0;
  }
}
