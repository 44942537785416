@import '/src/styles/colours';

.box {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  color: $brand-dark;
}

.success {
  background-color: $brand-light;
}

.error {
  background-color: $alert-pail;
}

.all_fields {
  background-color: $alert-light2;
  color: whitesmoke;
}

.wrapper {
  top: 200px !important;
  :global(.ant-notification) {
    top: 300px;
  }
}
