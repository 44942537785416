$white: #fff;
$lightBlue: #e5f1f9;
$blue: #0364ca;
$warningBackground: #fff1f0;
$warningBorder: #ffccc7;
$textBlack: black;
$backgroundWhite: #e7e6e6;
$white: white;
$hoverGray: #fafafa;
$hoverBlue: rgb(190, 213, 255);
$warningRed: #ff4d4f;
$successBackground: #f1ffe4;
$successGreen: #499401;
$successLightGreen: #37b323;

$brand-dark: #26355a;
$brand-light: rgba(126, 155, 204, 0.4);
$brand-medium: rgba(126, 155, 204, 0.7);
$neutral: #ebeaea;

$primary-light: #659ad1;
$primary-medium: #5c8dc0;
$primary-dark: #4f79a5;

$alert-pail: #fcc0bc5c;
$alert-light: #f0879a;
$alert-light2: #d35169;
$alert-medium: #bd4359;
$alert-dark: #a73348;

$confirm-light: #8cd280;
$confirm-medium: #82c078;
$confirm-dark: #6ea565;

$neutral-pail: #f5f5f5;
$neutral-light: #c8c2c2;
$neutral-dark: #9b9b9b;

$text-light: #5c5c5c;

$form-orange: #fc8738;

$silver-chalice: #f0f0f0;
