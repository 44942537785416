.tabs {
  :global(.ant-tabs-tab) {
    border-radius: 0 6px 0 0 !important;
  }
}

.wrapper {
  margin-bottom: 10px;
  :global(.ant-select) {
    width: fit-content !important;
    min-width: 300px !important;
    max-width: 100% !important;
  }
}
