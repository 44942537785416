.readOnly {
  pointer-events: none;

  :global(.ant-picker-suffix) {
    display: none !important;
  }

  &:global(.ant-picker) {
    background-color: white !important;
    color: black !important;
    border: 0px !important;
    box-shadow: none !important;
  }
  & :global(.ant-picker-input) {
    & > input {
      color: black !important;
    }
  }
}
