@import '/src/styles/colours';
@import '/src/styles/mixins';

.wrapper {
  flex-direction: column;
}

.tableWrapper {
  flex-grow: 1;
  margin-bottom: 20px;

  :global(.ant-table-cell) {
    border-inline-end: none !important;
  }

  :global(.ant-table-body) {
    @include scrollbar-color($neutral-light, $neutral-dark);
    overflow-y: scroll !important;
    flex-grow: 0 !important;
  }

  :global(.border-left) {
    border-left: 2px solid $silver-chalice !important;
  }

  :global(.ant-checkbox-disabled .ant-checkbox-inner) {
    background: $brand-medium !important;
    width: 23px !important;
    height: 23px !important;

    &::after {
      width: 6px;
      height: 14px;
      rotate: -3deg;
      scale: 1.05;
      border-bottom: 2px solid white;
      border-right: 2px solid white;
    }
  }

  :global(.ant-table-cell) {
    background-color: white;
  }
}

.scrollTable {
  height: 400px;
}

:global(
    .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td
  ) {
  border-inline-end: 0px !important;
}

.pagination {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

// :global(.ant-table-row) {
//   background: red !important;
// }

:global(.ant-table-row:hover td) {
  background: $hoverBlue !important;
  // color: beige;
}

:global(.ant-table-header) {
  border-radius: 0px !important;
}

:global(.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child) {
  border-radius: 0px !important;
}

.disabled-row {
  :global(.ant-table-cell) {
    background-color: $brand-light !important;
    opacity: 0.8;
    pointer-events: none;
  }
}
