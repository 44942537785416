@import '/src/styles/colours';

.wrapper {
  background-color: $white;
  padding: 20px;
}

.generisiWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  & .generateButton {
    align-self: flex-end;
  }
}

.ukupanKapacitetInfo {
  width: 40%;
  color: red;
  margin: 0 0 20px auto;
}
