.wrapper {
  :global(.ant-btn) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin-right: 5px;

    border-radius: 5px;

    span {
      font-weight: 500;
    }
    svg {
      color: white !important;
    }

    &:hover {
      background: transparent !important;
      z-index: 200;
      scale: 1.05;
      color: #659ad1 !important;
      border-color: #659ad1 !important;
      svg {
        color: #659ad1 !important;
      }
    }
  }
}

.excel {
  :global(.ant-btn) {
    background: green !important;
    color: white !important;
    svg {
      color: white !important;
    }

    &:hover {
      background: rgb(151 216 54) !important;
      color: green !important;
      svg {
        color: green !important;
      }
    }
  }
}
