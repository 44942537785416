@import '/src/styles/colours';

.devicesForWastewaterTreatment {
  :global(.ant-form-item) {
    margin-bottom: -10px;
  }
}

.text {
  font-weight: bold;
}

.warning {
  color: red;
}

.errorHeight {
  height: 20px;
}

.error {
  border: 1px solid $warningRed !important;
  border-radius: 10px;
}

.devicesWastewaterTreatmentList {
  padding: 10px;
  border: 1px solid rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
