.dozvolaNapomena {
  padding: 10px;
  width: 100%;
  & :global(.ant-form-item) {
    width: 100%;
  }
  & :global(.ant-col-6) {
    flex: 0 0 24%;
    max-width: 24%;
  }
}
