.printButtonContainer {
  display: flex;
  align-content: flex-end;
  flex-wrap: wrap;
}

.second-table {
  margin-top: 2rem;
}

:global(.vertical-align-top) {
  vertical-align: top !important;
}
.messageBox {
  text-align: center;
  color: red;
  background-color: white;
  padding: 20px;
}

.operationBox {
  justify-content: space-between;
  background-color: white;
  padding: 20px 10px;
}

.cancelSubtractBox {
  gap: 10px;
}

.subtractDateBox {
  align-items: center;
  gap: 10px;
}
